.AperturaContainerDestacadoBullets-parent { 
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: var(--streetSizeH);
	column-gap: 0rem;
    row-gap: 0rem;
	border-bottom: 1px solid var(--shadow-gray);
	padding-bottom: 1rem;
}

.primer-bloque {
	position: relative;
	padding: 0 1rem 0 0;
    display: grid;
	border-right: 1px solid var(--shadow-gray);
}
.ul-list {
	line-height: 0.8;
	padding: 0 0 0 1.5rem;
	margin-top: 0;
}

.ul-list li {
	margin: 0.75rem 0;
	padding: 0;
}
	
.segundo-bloque {
	position: relative;
	display: grid;
    row-gap: 1rem;
	height: max-content;
	padding: 0 0 0 1rem;
	grid-template-columns: 1fr;
}

.tercer-bloque {
	position: relative;
	display: grid;
    grid-template-columns: 1fr;
    row-gap: 0rem;
	padding: 1rem 0 0 0;
	border-bottom: 0px solid var(--shadow-gray);
}

.grid-item {
	position: relative;
}


@media (max-width: 1024px) {
	.AperturaContainerDestacadoBullets-parent { 
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: var(--streetSizeH);
		column-gap: 1rem;
		row-gap: 0rem;
		border-bottom: 1px solid var(--shadow-gray);
		padding-bottom: 1rem;
	}	
	.primer-bloque {
		position: relative;
		display: contents;
		padding: 0;
		border-right: 0;
		display: grid;
		grid-area: 1 / 1 / auto / 5;
	}

	.segundo-bloque {
		position: relative;
		display: flex;
		grid-area: 2 / 1 / auto / 5;
		grid-row-gap: 2rem;
		row-gap: 2rem;
		column-gap: 2rem;
		height: -moz-max-content;
		height: max-content;
		padding: 0;
		margin: 1rem 0 0 1rem;
		float: left;
	}
	.segundo-bloque div:first-child {
		border-right: 0px solid var(--shadow-gray);
		margin-right: 0rem;
	}
	.tercer-bloque {
		border-top: 0px solid var(--shadow-gray);
		padding: 0;
	}

	}

@media (max-width: 768px) {
	.AperturaContainerDestacadoBullets-parent {
		grid-template-columns: 1fr;
		display: grid;
	}

	.primer-bloque {
		padding-bottom: 2.2rem;
		padding: 0;
		border-right: 0;
		margin-bottom: 1rem;
		grid-template-columns: 1fr;
		grid-area: auto;
		display: grid;

	}
	.segundo-bloque {
		padding: 0;
		margin: 0;
		grid-template-columns: 1fr;
		grid-area: auto;
		display: grid;
		row-gap: 1rem;
		height: min-content;
	}
	.tercer-bloque {
		padding: 0;
		grid-template-columns: 1fr;
		grid-area: auto;
		display: grid;
	}
	.segundo-bloque div:first-child {
		border-right: 0;
		margin-right: 0;
	}
	.ul-list {
		padding: 0 0 0 1rem;
	}
}
